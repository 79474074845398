import $ from 'jquery';

/** import local dependencies */
import './webflow.js';
import 'slick-carousel';

import Headroom from "headroom.js";

/**
 * Anonymous function is autoexecutable.
 */
 (function () {

  function getOffsetValue(){
    let pos = document.querySelector("header").offsetTop;
    return pos+120;
  }

  var myElement = document.querySelector("header");

  var options = {
    offset: getOffsetValue(),
    tolerance : 0,
  };
  var headroom  = new Headroom(myElement, options);
  headroom.init(); 

  window.addEventListener("resize", function(e) {
    headroom.offset = getOffsetValue();
  }, false);

  let initialHash = location.hash;
  const $html = document.querySelector('html');

  function openModal(hash, start){
    closeModal();
    let $el = document.querySelector(hash);
    if ( $el && $el.classList.contains('modal') ) {
      $html.classList.add('overflow');
      $el.classList.add('open');
      if($($el).find('.flex-image-carousel').length > 0){
        if(start){

        }else{
          $($el).find('.flex-image-carousel').slick('setPosition');
        }
      }
    }
  }

  function closeModal(){
    document.querySelectorAll('.modal').forEach($el => {
      $html.classList.remove('overflow');
      $el.classList.remove('open');
    });
  }

  function cleanHash(){
    history.pushState(null, null, '#');
  }

  window.addEventListener('hashchange', () => {
    let hash = window.location.hash;
    console.log(hash);
    if ( hash !== '' ) {
      openModal(hash, false);
    } else {
      closeModal();
    }
  });


  if (initialHash.length > 0) {
    openModal(initialHash, true);
  }

  document.addEventListener('keydown', function(e) {
    if (e.which === 27) {
      closeModal();
      cleanHash();
    }
  });

  if($('.modal-close').length > 0){
    $('.modal-close').on('click', function(e){
      e.preventDefault;
      closeModal();
      cleanHash();
    });
  }

  window.onclick = function(event) {
    if(event.target.classList.contains('modal')){
      closeModal();
      cleanHash();
    }
  }

  /*
    * The below example uses Slick Carousel, however this can be
    * extended into any type of carousel, provided it lets you
    * bind events when the slide changes. This will only work
    * if all framed videos have the JS API parameters enabled.
    */
  
  //bind our event here, it gets the current slide and pauses the video before each slide changes.
  $(".flex-image-carousel").on("beforeChange", function(event, slick) {
    var currentSlide, slideType, player, command;
    
    //find the current slide element and decide which player API we need to use.
    currentSlide = $(slick.$slider).find(".slick-current");
    
    //determine which type of slide this, via a class on the slide container. This reads the second class, you could change this to get a data attribute or something similar if you don't want to use classes.
    slideType = currentSlide.attr("class").split(" ")[1];
    //console.log(slideType);
    
    //get the iframe inside this slide.
    player = currentSlide.find("iframe").get(0);
    //console.log('player', player);
    
    if (slideType == "vimeo") {
      command = {
        "method": "pause",
        "value": "true"
      };
    } else {
      command = {
        "event": "command",
        "func": "pauseVideo"
      };
    }
    
    //check if the player exists.
    if (player != undefined) {
      //post our command to the iframe.
      player.contentWindow.postMessage(JSON.stringify(command), "*");
    }
  });
  
  //start the slider
  $(".flex-image-carousel").slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true
  });

  const agentRow = document.getElementById('sf-real-estate-agent');
  if(agentRow) {
    agentRow.onchange = (ev) => {
      console.log(ev.currentTarget, ev.currentTarget.value);
      
      if ( ev.currentTarget.value === 'Yes' ) {
        console.log(document.getElementsByClassName('form-block-hidden'));
        document.getElementsByClassName('form-block-hidden')[0].classList.add('open');
        document.getElementById('sf-agent-name').setAttribute('required', true);
        document.getElementById('sf-agent-email').setAttribute('required', true);
      } else {
        document.getElementsByClassName('form-block-hidden')[0].classList.remove('open');
        document.getElementById('sf-agent-name').removeAttribute('required');
        document.getElementById('sf-agent-email').removeAttribute('required');
      }
    }
  }

  // ************************ Pop-Up ***************** //
  const popups = document.getElementsByClassName('popup');
  for (let i = 0; i < popups.length; i++) {
    const pop = popups[i];
    pop.onclick = (ev) => {
      const isAnchor = ev.currentTarget.tagName === 'A';
      console.log('ev', isAnchor );
      const target = (isAnchor) ? document.querySelector(ev.currentTarget.getAttribute('rel')) : ev.currentTarget;
      if(!isAnchor && ev.target.closest('.innercontent'))
        return false;
      target.classList.toggle('visible');
    }
  }

  // ************************ checkbox with Pop-Up ***************** //
  function setFakeChecker(check, poptarget) {
    if ( !check ) return null;

    check.onchange = (ev) => {
      if (ev.currentTarget.checked) {
        document.getElementById(poptarget).classList.add('visible');
        ev.currentTarget.checked = false;
      }
    }
  }

  function setTrueChecker(check2, poptarget) {
    
    if ( !check2 ) return null;

    check2.onchange = function(ev) {
      console.log('ev', ev);
      console.log('click on ', check2.id)
      if (ev.currentTarget.checked) {
        console.log('related', ev.target, ev.currentTarget, ev.currentTarget.getAttribute('data-rel'))

        const relatedCheck = document.getElementById(ev.currentTarget.getAttribute('data-rel'))
        relatedCheck.checked = true;
        document.getElementById(poptarget).classList.remove('visible');

        ev.currentTarget.checked = false;
      }
    }
  }

  setFakeChecker(document.getElementById('sf-process'), 'preferenceProcessPop');
  setTrueChecker(document.getElementById('preferenceProcessAccept'), 'preferenceProcessPop');

  setFakeChecker(document.getElementById('sf-preconstruction-guide'), 'preconstructionPop');
  setTrueChecker(document.getElementById('preconstructionAccept'), 'preconstructionPop');
    

})();